@media (max-width: 767px) {
	div.app {
		div.content {
			div.dx-widget,
			span.dx-box-item-content {
				font-size: 0.8em;
			}

			div.content-block {
				margin-left: 0;
				margin-right: 0;
			}

			div.dx-datagrid {

				td.dx-master-detail-cell {
					padding: 10px 5px;
	
					div.dx-datagrid-edit-form-item {
						flex-direction: column;
						font-size: 0.8em;
					}
				}
			}
			
		}
	} 
}