.content h3 {
    font-size: 24px!important;
    margin-top: 0;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
}

div.app {
    div.content {
        div.dx-datagrid {
            div.dx-button {
                font-size: 14px;
            }
        }
    }
}