.content .dx-card {
	margin: 0;
}

.button-load-indicator {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}



