@import "../../themes/generated/variables.base.scss";

.single-card {

	.dx-card {
		width: 330px;
		margin: auto auto;
		padding: 40px;
		flex-grow: 0;

		.screen-x-small & {
			width: 100%;
			height: 100%;
			border-radius: 0;
			box-shadow: none;
			margin: 0;
			border: 0;
			flex-grow: 1;
		}

		.header {
			margin-bottom: 30px;

			.title {
				color: $base-text-color;
				line-height: 28px;
				font-weight: 500;
				font-size: 24px;
			}

			.description {
				color: rgba($base-text-color, alpha($base-text-color) * 0.7);
				line-height: 18px;
			}
		}
	}
}