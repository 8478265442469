@media (max-width: 768px) {
    div.list-container {
        div.dx-list-group {
            div.full-menu-item {
                display: block;
                text-align: center;
            }
            div.dx-item-content {
                text-align: center;
            }
        }
    }
}