@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
	flex: 0 0 auto;
	z-index: 1;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

	&.header-scanette .dx-toolbar-items-container {
		height: 50px!important;
	}

	.dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
		color: $base-accent;
	}
}

.header-toolbar {
	background: rgb(196,75,127);
	background: linear-gradient(90deg, rgba(196,75,127,1) 0%, rgba(76,79,152,1) 53%);
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
	padding: 0 40px;

	.screen-x-small & {
		padding: 0 20px;
	}
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
	width: $side-panel-min-width;
	text-align: center;
	padding: 0;
}

.header-title .dx-item-content {
	padding: 0;
	margin: 0;
}

.dx-theme-generic {
	.dx-toolbar {
		padding: 10px 0;
	}

	.user-button>.dx-button-content {
		padding: 3px;
	}
}