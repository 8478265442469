@import "../../themes/generated/variables.additional.scss";
.side-nav-inner-toolbar {
    width: 100%;
  }
  
  .container {
    height: 100%;
    flex-direction: column;
    display: flex;

    .content-block.scanette {
      font-size: 0.7em;
    }
  }
  
  .layout-body {
    flex: 1;
    min-height: 0;
  }
  
  .content {
    flex-grow: 1;
  }
  
  #navigation-header {
    
    background-color: $base-accent;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .header-title,
    .menu-button .dx-icon {
      color: $base-bg;
    }
  
    .screen-x-small & {
      padding-left: 20px;
    }
      
    .dx-theme-generic & {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  