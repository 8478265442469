.content .dx-card {
	

	div.dx-textbox {
		margin-bottom: 4px;
	}

	div.dx-datagrid {

		div.dx-toolbar div.dx-toolbar-after {
			display: block;
			padding-top: 5px;
			padding-right: 15px;
		}

		table.dx-datagrid-table tr.row-expand td {
			background-color: rgba(152, 171, 233, 0.384)!important;
		}
	}
}

.dx-toast-wrapper {
	z-index: 9600!important;
	margin: 50px;
}
  